import React, { useState, useEffect } from "react";
import { Select } from "@mantine/core";
import { FaGlobe } from "react-icons/fa";
//for multi-lingual support
import { useTranslation } from "react-i18next";
import getGroups from "../components/home/getGroups";
import { CheckUserZones } from "./helpers/checkUserZones";
import Cookies from "js-cookie";

function SwitchZone() {
  const { i18n, t } = useTranslation();
  const [selectedZone, setSelectedZone] = useState(
    localStorage.getItem("mje_zone_selected")
  );
  const [userZonesOptions, setUserZonesOptions] = useState([]);

  let userZonesTemp = [];
  let newArr = [];

  const [executions, setExecutions] = useState(0);

  //check the zones users has access to
  useEffect(() => {
    if (executions < 4) {
      const timeoutId = setTimeout(async () => {
        await CheckUserZones(userZonesTemp, newArr, setUserZonesOptions);
        setExecutions(executions + 1);

        return () => clearTimeout(timeoutId);
      });
    }
  }, [executions]);

  //handle the change for the selected zone
  const handleChangeButtonClick = async (event) => {
    Cookies.set("lastLoggedZone", event, {
      expires: 360,
    });
    if (event == "NAZ") {
      window.location.href = "/home";
    } else if (event == "COGNOS MJE") {
      const nazUsers = localStorage.getItem("roles");
      if (nazUsers.includes("COGNOS_Admin")) {
        window.location.href = "/cognosmje-admin";
      } else {
        window.location.href = "/home";
      }
    } else {
      localStorage.setItem("mje_zone_selected", event);
      setSelectedZone(event);
      await getGroups(event);
      window.location.href = "/v2/jeanInsights";
    }
  };
  return (
    <Select
      title="Switch Zone"
      icon={<FaGlobe style={{ color: "#e3af32" }} />}
      placeholder={t("profile_toast_placeholder7")}
      id="demo-controlled-open-select"
      value={window.location.pathname.includes("/v2/") ? selectedZone : ""}
      onChange={handleChangeButtonClick}
      size="sm"
      radius="lg"
      style={{
        width: "150px",
        margin: "auto",
      }}
      data={userZonesOptions}
    ></Select>
  );
}

export default SwitchZone;
